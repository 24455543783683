/**
 * @generated SignedSource<<07de8859a9e013f4b00bc33adad945a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Link_link$data = {
  readonly displayParentTopics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly displayName: string;
        readonly id: string;
      };
    } | null | undefined> | null | undefined;
  };
  readonly displayTitle: string;
  readonly displayUrl: string;
  readonly id: string;
  readonly loading: boolean;
  readonly newlyAdded: boolean;
  readonly repoLinks: ReadonlyArray<{
    readonly displayColor: any;
    readonly inWikiRepo: boolean;
  }>;
  readonly showRepoOwnership: boolean;
  readonly viewerCanUpdate: boolean;
  readonly " $fragmentType": "Link_link";
};
export type Link_link$key = {
  readonly " $data"?: Link_link$data;
  readonly " $fragmentSpreads": FragmentRefs<"Link_link">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Link_link",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUrl",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newlyAdded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showRepoOwnership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoLink",
      "kind": "LinkedField",
      "name": "repoLinks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inWikiRepo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayColor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "TopicConnection",
      "kind": "LinkedField",
      "name": "displayParentTopics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "displayParentTopics(first:100)"
    }
  ],
  "type": "Link",
  "abstractKey": null
};
})();

(node as any).hash = "08796f1bc72bf7ef8df90b7f60d87a65";

export default node;
