/**
 * @generated SignedSource<<77ce7481f19e7bf931ac1c9c2f0a5ec7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LocaleIdentifier = "ar" | "de" | "el" | "en" | "es" | "fa" | "fi" | "fr" | "hi" | "it" | "ja" | "ji" | "ko" | "la" | "nl" | "no" | "pt" | "ru" | "sv" | "tr" | "uk" | "zh" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ViewRepoTopic_repoTopic$data = {
  readonly details: {
    readonly synonyms: ReadonlyArray<{
      readonly locale: LocaleIdentifier;
      readonly name: string;
    }>;
  } | null | undefined;
  readonly displayColor: any;
  readonly repoId: string;
  readonly timerangePrefix: string;
  readonly " $fragmentType": "ViewRepoTopic_repoTopic";
};
export type ViewRepoTopic_repoTopic$key = {
  readonly " $data"?: ViewRepoTopic_repoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewRepoTopic_repoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewRepoTopic_repoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerangePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoTopicDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Synonym",
          "kind": "LinkedField",
          "name": "synonyms",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepoTopic",
  "abstractKey": null
};

(node as any).hash = "d4eb29a0cc0db811e42ee1efc452c9c0";

export default node;
