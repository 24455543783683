/**
 * @generated SignedSource<<9a16562d88b9ec89608b0e20242b0314>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type TimerangePrefixFormat = "NONE" | "START_YEAR" | "START_YEAR_MONTH" | "%future added value";
export type UpsertTopicTimerangeInput = {
  clientMutationId?: string | null | undefined;
  endsAt?: any | null | undefined;
  prefixFormat: TimerangePrefixFormat;
  repoId: string;
  startsAt: any;
  topicId: string;
};
export type upsertTopicTimerangeMutation$variables = {
  input: UpsertTopicTimerangeInput;
};
export type upsertTopicTimerangeMutation$data = {
  readonly upsertTopicTimerange: {
    readonly alerts: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
      readonly type: AlertType;
    }>;
    readonly updatedRepoTopic: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerange_repoTopic">;
    };
    readonly updatedTopic: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EditTopic_topic">;
    };
  } | null | undefined;
};
export type upsertTopicTimerangeMutation = {
  response: upsertTopicTimerangeMutation$data;
  variables: upsertTopicTimerangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Timerange",
  "kind": "LinkedField",
  "name": "timerange",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefixFormat",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "upsertTopicTimerangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertTopicTimerangePayload",
        "kind": "LinkedField",
        "name": "upsertTopicTimerange",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "updatedTopic",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditTopic_topic"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RepoTopicTimerange_repoTopic"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "upsertTopicTimerangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpsertTopicTimerangePayload",
        "kind": "LinkedField",
        "name": "upsertTopicTimerange",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "updatedTopic",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoTopic",
                "kind": "LinkedField",
                "name": "repoTopics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Repository",
                    "kind": "LinkedField",
                    "name": "repo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanUpdate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayColor",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repoId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timerangePrefix",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanDeleteSynonyms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RepoTopicDetails",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Synonym",
                        "kind": "LinkedField",
                        "name": "synonyms",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "selectedTopics",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1000
                      }
                    ],
                    "concreteType": "TopicConnection",
                    "kind": "LinkedField",
                    "name": "parentTopics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopicEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Topic",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "value",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "id",
                                "storageKey": null
                              },
                              {
                                "alias": "label",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "parentTopics(first:1000)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoTopicDetails",
                "kind": "LinkedField",
                "name": "details",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90e228c3255682680135522d924f341a",
    "id": null,
    "metadata": {},
    "name": "upsertTopicTimerangeMutation",
    "operationKind": "mutation",
    "text": "mutation upsertTopicTimerangeMutation(\n  $input: UpsertTopicTimerangeInput!\n) {\n  upsertTopicTimerange(input: $input) {\n    alerts {\n      id\n      text\n      type\n    }\n    updatedTopic {\n      id\n      ...EditTopic_topic\n    }\n    updatedRepoTopic {\n      id\n      ...RepoTopicTimerange_repoTopic\n    }\n  }\n}\n\nfragment EditRepoTopic_repoTopic on RepoTopic {\n  topicId\n  displayColor\n  ...RepoTopicSynonyms_repoTopic\n  ...RepoTopicTimerange_repoTopic\n  ...RepoTopicParentTopics_repoTopic\n}\n\nfragment EditTopic_topic on Topic {\n  displayName\n  repoTopics {\n    repo {\n      name\n      id\n    }\n    viewerCanUpdate\n    displayColor\n    ...EditRepoTopic_repoTopic\n    ...ViewRepoTopic_repoTopic\n  }\n}\n\nfragment RepoTopicParentTopics_repoTopic on RepoTopic {\n  topicId\n  selectedTopics: parentTopics(first: 1000) {\n    edges {\n      node {\n        value: id\n        label: displayName\n      }\n    }\n  }\n}\n\nfragment RepoTopicSynonyms_repoTopic on RepoTopic {\n  id\n  repoId\n  timerangePrefix\n  topicId\n  viewerCanDeleteSynonyms\n  viewerCanUpdate\n  details {\n    synonyms {\n      name\n      locale\n    }\n  }\n}\n\nfragment RepoTopicTimerangeForm_repoTopic on RepoTopic {\n  topicId\n  details {\n    timerange {\n      startsAt\n      prefixFormat\n    }\n  }\n}\n\nfragment RepoTopicTimerange_repoTopic on RepoTopic {\n  id\n  topicId\n  details {\n    timerange {\n      startsAt\n    }\n  }\n  ...RepoTopicTimerangeForm_repoTopic\n}\n\nfragment ViewRepoTopic_repoTopic on RepoTopic {\n  displayColor\n  repoId\n  timerangePrefix\n  details {\n    synonyms {\n      name\n      locale\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f339210036f3a418847b995622b1f41";

export default node;
