/**
 * @generated SignedSource<<8896324adadd8bfba02abf3f326e7040>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LocaleIdentifier = "ar" | "de" | "el" | "en" | "es" | "fa" | "fi" | "fr" | "hi" | "it" | "ja" | "ji" | "ko" | "la" | "nl" | "no" | "pt" | "ru" | "sv" | "tr" | "uk" | "zh" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RepoTopicSynonyms_repoTopic$data = {
  readonly details: {
    readonly synonyms: ReadonlyArray<{
      readonly locale: LocaleIdentifier;
      readonly name: string;
    }>;
  } | null | undefined;
  readonly id: string;
  readonly repoId: string;
  readonly timerangePrefix: string;
  readonly topicId: string;
  readonly viewerCanDeleteSynonyms: boolean;
  readonly viewerCanUpdate: boolean;
  readonly " $fragmentType": "RepoTopicSynonyms_repoTopic";
};
export type RepoTopicSynonyms_repoTopic$key = {
  readonly " $data"?: RepoTopicSynonyms_repoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicSynonyms_repoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoTopicSynonyms_repoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "repoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerangePrefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanDeleteSynonyms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoTopicDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Synonym",
          "kind": "LinkedField",
          "name": "synonyms",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepoTopic",
  "abstractKey": null
};

(node as any).hash = "e3b82b5d1909ee50a68ced2874327445";

export default node;
