/**
 * @generated SignedSource<<62e29207827d1442005d6d64b3bf3ba7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimerangePrefixFormat = "NONE" | "START_YEAR" | "START_YEAR_MONTH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RepoTopicTimerangeForm_repoTopic$data = {
  readonly details: {
    readonly timerange: {
      readonly prefixFormat: TimerangePrefixFormat;
      readonly startsAt: any;
    } | null | undefined;
  } | null | undefined;
  readonly topicId: string;
  readonly " $fragmentType": "RepoTopicTimerangeForm_repoTopic";
};
export type RepoTopicTimerangeForm_repoTopic$key = {
  readonly " $data"?: RepoTopicTimerangeForm_repoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerangeForm_repoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoTopicTimerangeForm_repoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoTopicDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Timerange",
          "kind": "LinkedField",
          "name": "timerange",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "prefixFormat",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepoTopic",
  "abstractKey": null
};

(node as any).hash = "6d0d9b848e96feeb91ce96db7d856b20";

export default node;
