/**
 * @generated SignedSource<<e84acb941d91bd0b9d315d25c700609d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type UpdateTopicSynonymsInput = {
  clientMutationId?: string | null | undefined;
  repoId: string;
  synonyms: ReadonlyArray<SynonymInput>;
  topicId: string;
};
export type SynonymInput = {
  locale: string;
  name: string;
};
export type updateTopicSynonymsMutation$variables = {
  input: UpdateTopicSynonymsInput;
};
export type updateTopicSynonymsMutation$data = {
  readonly updateTopicSynonyms: {
    readonly alerts: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
      readonly type: AlertType;
    }>;
    readonly clientMutationId: string | null | undefined;
    readonly updatedRepoTopic: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RepoTopicSynonyms_repoTopic">;
    };
    readonly updatedTopic: {
      readonly displayName: string;
      readonly id: string;
    };
  } | null | undefined;
};
export type updateTopicSynonymsMutation = {
  response: updateTopicSynonymsMutation$data;
  variables: updateTopicSynonymsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Topic",
  "kind": "LinkedField",
  "name": "updatedTopic",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTopicSynonymsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTopicSynonymsPayload",
        "kind": "LinkedField",
        "name": "updateTopicSynonyms",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RepoTopicSynonyms_repoTopic"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTopicSynonymsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTopicSynonymsPayload",
        "kind": "LinkedField",
        "name": "updateTopicSynonyms",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "repoId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timerangePrefix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanDeleteSynonyms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoTopicDetails",
                "kind": "LinkedField",
                "name": "details",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Synonym",
                    "kind": "LinkedField",
                    "name": "synonyms",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locale",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "19ee4b2859daecc19f23a5554f1f117b",
    "id": null,
    "metadata": {},
    "name": "updateTopicSynonymsMutation",
    "operationKind": "mutation",
    "text": "mutation updateTopicSynonymsMutation(\n  $input: UpdateTopicSynonymsInput!\n) {\n  updateTopicSynonyms(input: $input) {\n    clientMutationId\n    alerts {\n      id\n      text\n      type\n    }\n    updatedTopic {\n      id\n      displayName\n    }\n    updatedRepoTopic {\n      id\n      ...RepoTopicSynonyms_repoTopic\n    }\n  }\n}\n\nfragment RepoTopicSynonyms_repoTopic on RepoTopic {\n  id\n  repoId\n  timerangePrefix\n  topicId\n  viewerCanDeleteSynonyms\n  viewerCanUpdate\n  details {\n    synonyms {\n      name\n      locale\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "920775032b37bf229db3b0521e32b6b4";

export default node;
