/**
 * @generated SignedSource<<25495d4773db38958a170ab487367e50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Topic_viewer$data = {
  readonly id: string | null | undefined;
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentType": "Topic_viewer";
};
export type Topic_viewer$key = {
  readonly " $data"?: Topic_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Topic_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Topic_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c94e59d298813998256b47970248b899";

export default node;
