/**
 * @generated SignedSource<<700220e92b6bf661b658714005346ca4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepoTopicTimerange_viewer$data = {
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerangeForm_viewer">;
  readonly " $fragmentType": "RepoTopicTimerange_viewer";
};
export type RepoTopicTimerange_viewer$key = {
  readonly " $data"?: RepoTopicTimerange_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerange_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoTopicTimerange_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicTimerangeForm_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0a89a0eadd42b82e0ebb398854db6d7d";

export default node;
