/**
 * @generated SignedSource<<9d2ada9564471acc7467c9da7277a3bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditLinkQuery$variables = {
  linkId: string;
  repoIds?: ReadonlyArray<string> | null | undefined;
  viewerId: string;
};
export type EditLinkQuery$data = {
  readonly view: {
    readonly link: {
      readonly " $fragmentSpreads": FragmentRefs<"EditLink_link">;
    } | null | undefined;
    readonly viewer: {
      readonly " $fragmentSpreads": FragmentRefs<"EditLink_viewer">;
    };
  };
};
export type EditLinkQuery = {
  response: EditLinkQuery$data;
  variables: EditLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "linkId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "repoIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "repoIds",
    "variableName": "repoIds"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "linkId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditLink_viewer"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Link",
            "kind": "LinkedField",
            "name": "link",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditLink_link"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLinkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "selectedRepoId",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Link",
            "kind": "LinkedField",
            "name": "link",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoLink",
                "kind": "LinkedField",
                "name": "repoLinks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Repository",
                    "kind": "LinkedField",
                    "name": "repo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerCanUpdate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RepoLinkDetails",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "selectedTopics",
                    "args": null,
                    "concreteType": "TopicConnection",
                    "kind": "LinkedField",
                    "name": "parentTopics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopicEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Topic",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "value",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "id",
                                "storageKey": null
                              },
                              {
                                "alias": "label",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15cfe97370cdb2c9343b451457434df2",
    "id": null,
    "metadata": {},
    "name": "EditLinkQuery",
    "operationKind": "query",
    "text": "query EditLinkQuery(\n  $linkId: ID!\n  $repoIds: [ID!]\n  $viewerId: ID!\n) {\n  view(repoIds: $repoIds, viewerId: $viewerId) {\n    viewer {\n      ...EditLink_viewer\n      id\n    }\n    link(id: $linkId) {\n      ...EditLink_link\n    }\n  }\n}\n\nfragment EditLink_link on Link {\n  displayTitle\n  repoLinks {\n    repo {\n      name\n      id\n    }\n    viewerCanUpdate\n    displayColor\n    ...EditRepoLink_repoLink\n    ...ViewRepoLink_repoLink\n  }\n}\n\nfragment EditLink_viewer on User {\n  selectedRepoId\n  ...EditRepoLink_viewer\n}\n\nfragment EditRepoLink_repoLink on RepoLink {\n  displayColor\n  linkId\n  details {\n    title\n    url\n  }\n  ...RepoLinkParentTopics_repoLink\n}\n\nfragment EditRepoLink_viewer on User {\n  id\n  selectedRepoId\n}\n\nfragment RepoLinkParentTopics_repoLink on RepoLink {\n  linkId\n  selectedTopics: parentTopics {\n    edges {\n      node {\n        value: id\n        label: displayName\n      }\n    }\n  }\n}\n\nfragment ViewRepoLink_repoLink on RepoLink {\n  displayColor\n  details {\n    title\n  }\n}\n"
  }
};
})();

(node as any).hash = "93e5e8c4ac37b0877378d7efbc7cc527";

export default node;
