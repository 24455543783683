/**
 * @generated SignedSource<<4fe147f2a084b17711a9af8dc18c9bb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type DeleteAccountInput = {
  clientMutationId?: string | null | undefined;
  userId: string;
};
export type deleteAccountMutation$variables = {
  input: DeleteAccountInput;
};
export type deleteAccountMutation$data = {
  readonly deleteAccount: {
    readonly alerts: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
      readonly type: AlertType;
    }>;
    readonly clientMutationId: string | null | undefined;
    readonly deletedUserId: string;
  } | null | undefined;
};
export type deleteAccountMutation = {
  response: deleteAccountMutation$data;
  variables: deleteAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAccountPayload",
    "kind": "LinkedField",
    "name": "deleteAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedUserId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Alert",
        "kind": "LinkedField",
        "name": "alerts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53014b8f2ea3d920cd6c2fc19ee37266",
    "id": null,
    "metadata": {},
    "name": "deleteAccountMutation",
    "operationKind": "mutation",
    "text": "mutation deleteAccountMutation(\n  $input: DeleteAccountInput!\n) {\n  deleteAccount(input: $input) {\n    clientMutationId\n    deletedUserId\n    alerts {\n      id\n      text\n      type\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "73daf7e169e3680240135863c7fb89e7";

export default node;
