/**
 * @generated SignedSource<<d0390ec4c5e5082f1bf39a07a276e7b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddTopic_viewer$data = {
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentType": "AddTopic_viewer";
};
export type AddTopic_viewer$key = {
  readonly " $data"?: AddTopic_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddTopic_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddTopic_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c72bc0e15c61ad08d2d2837e29a0603e";

export default node;
