/**
 * @generated SignedSource<<db20ab6c25d1fe845f39f323364bd506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteAccount_view$data = {
  readonly viewer: {
    readonly id: string | null | undefined;
  };
  readonly " $fragmentType": "DeleteAccount_view";
};
export type DeleteAccount_view$key = {
  readonly " $data"?: DeleteAccount_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteAccount_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteAccount_view",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};

(node as any).hash = "d76e084b33f6380f0e7dde8930269e4a";

export default node;
