const defaultOrganizationId = '45dc89a6-e6f0-11e8-8bc1-6f4d565e3ddb'
const defaultOrganizationLogin = 'wiki'
const wikiRepoId = '32212616-fc1b-11e8-8eda-b70af6d8d09f'
const everythingTopicId = 'lBwR6Cvz4btdI23oscsp7THRytHohlol4o2IkqxcFN8'

/* eslint import/prefer-default-export: 0 */
export {
  defaultOrganizationId,
  defaultOrganizationLogin,
  everythingTopicId,
  wikiRepoId,
}
