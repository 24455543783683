/**
 * @generated SignedSource<<f57b06a42338a94f718aca6f5b7ce3cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TopicSuggestionsQuery$variables = {
  searchString?: string | null | undefined;
};
export type TopicSuggestionsQuery$data = {
  readonly view: {
    readonly topicLiveSearch: {
      readonly synonyms: ReadonlyArray<{
        readonly link: string;
        readonly name: string;
      }>;
    };
  };
};
export type TopicSuggestionsQuery = {
  response: TopicSuggestionsQuery$data;
  variables: TopicSuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "viewerId",
        "value": ""
      }
    ],
    "concreteType": "View",
    "kind": "LinkedField",
    "name": "view",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "searchString",
            "variableName": "searchString"
          }
        ],
        "concreteType": "LiveSearchTopicsPayload",
        "kind": "LinkedField",
        "name": "topicLiveSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SynonymEntry",
            "kind": "LinkedField",
            "name": "synonyms",
            "plural": true,
            "selections": [
              {
                "alias": "name",
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": "link",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "view(viewerId:\"\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicSuggestionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopicSuggestionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eeb306df4b37208beb4bc530fd7d4ae6",
    "id": null,
    "metadata": {},
    "name": "TopicSuggestionsQuery",
    "operationKind": "query",
    "text": "query TopicSuggestionsQuery(\n  $searchString: String\n) {\n  view(viewerId: \"\") {\n    topicLiveSearch(searchString: $searchString) {\n      synonyms {\n        name: displayName\n        link: id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fc43992c4969bae8428d2414b43ffcf";

export default node;
