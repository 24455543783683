/**
 * @generated SignedSource<<3c2d48bb5d3e0c2d47ff93f845288dea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveTopicTimerangeInput = {
  clientMutationId?: string | null | undefined;
  repoId: string;
  topicId: string;
};
export type removeTopicTimerangeMutation$variables = {
  input: RemoveTopicTimerangeInput;
};
export type removeTopicTimerangeMutation$data = {
  readonly removeTopicTimerange: {
    readonly clientMutationId: string | null | undefined;
    readonly updatedRepoTopic: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerange_repoTopic">;
    };
    readonly updatedTopic: {
      readonly displayName: string;
      readonly id: string;
    };
  } | null | undefined;
};
export type removeTopicTimerangeMutation = {
  response: removeTopicTimerangeMutation$data;
  variables: removeTopicTimerangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Topic",
  "kind": "LinkedField",
  "name": "updatedTopic",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeTopicTimerangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveTopicTimerangePayload",
        "kind": "LinkedField",
        "name": "removeTopicTimerange",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RepoTopicTimerange_repoTopic"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeTopicTimerangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveTopicTimerangePayload",
        "kind": "LinkedField",
        "name": "removeTopicTimerange",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RepoTopic",
            "kind": "LinkedField",
            "name": "updatedRepoTopic",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoTopicDetails",
                "kind": "LinkedField",
                "name": "details",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Timerange",
                    "kind": "LinkedField",
                    "name": "timerange",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefixFormat",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa9f6fafd361bd36b9580841ba0d6bd8",
    "id": null,
    "metadata": {},
    "name": "removeTopicTimerangeMutation",
    "operationKind": "mutation",
    "text": "mutation removeTopicTimerangeMutation(\n  $input: RemoveTopicTimerangeInput!\n) {\n  removeTopicTimerange(input: $input) {\n    clientMutationId\n    updatedTopic {\n      id\n      displayName\n    }\n    updatedRepoTopic {\n      id\n      ...RepoTopicTimerange_repoTopic\n    }\n  }\n}\n\nfragment RepoTopicTimerangeForm_repoTopic on RepoTopic {\n  topicId\n  details {\n    timerange {\n      startsAt\n      prefixFormat\n    }\n  }\n}\n\nfragment RepoTopicTimerange_repoTopic on RepoTopic {\n  id\n  topicId\n  details {\n    timerange {\n      startsAt\n    }\n  }\n  ...RepoTopicTimerangeForm_repoTopic\n}\n"
  }
};
})();

(node as any).hash = "3fd94248f0d1ac09c08442f516fc6e9b";

export default node;
