/**
 * @generated SignedSource<<5ac84a0a8d8dbb68817f6cee0179e473>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RepoLinkParentTopicsRefetchQuery$variables = {
  linkId: string;
  searchString?: string | null | undefined;
  selectedRepoId: string;
  viewerId: string;
};
export type RepoLinkParentTopicsRefetchQuery$data = {
  readonly view: {
    readonly link: {
      readonly repoLink: {
        readonly availableParentTopics: {
          readonly synonyms: ReadonlyArray<{
            readonly label: string;
            readonly value: string;
          }>;
        };
      } | null | undefined;
    } | null | undefined;
  };
};
export type RepoLinkParentTopicsRefetchQuery = {
  response: RepoLinkParentTopicsRefetchQuery$data;
  variables: RepoLinkParentTopicsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "linkId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchString"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedRepoId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "viewerId",
        "variableName": "viewerId"
      }
    ],
    "concreteType": "View",
    "kind": "LinkedField",
    "name": "view",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "linkId"
          }
        ],
        "concreteType": "Link",
        "kind": "LinkedField",
        "name": "link",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "repoId",
                "variableName": "selectedRepoId"
              }
            ],
            "concreteType": "RepoLink",
            "kind": "LinkedField",
            "name": "repoLink",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "searchString",
                    "variableName": "searchString"
                  }
                ],
                "concreteType": "LiveSearchTopicsPayload",
                "kind": "LinkedField",
                "name": "availableParentTopics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SynonymEntry",
                    "kind": "LinkedField",
                    "name": "synonyms",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "value",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": "label",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepoLinkParentTopicsRefetchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepoLinkParentTopicsRefetchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9dd418425791473dca2336a18890d46",
    "id": null,
    "metadata": {},
    "name": "RepoLinkParentTopicsRefetchQuery",
    "operationKind": "query",
    "text": "query RepoLinkParentTopicsRefetchQuery(\n  $linkId: ID!\n  $searchString: String\n  $selectedRepoId: ID!\n  $viewerId: ID!\n) {\n  view(viewerId: $viewerId) {\n    link(id: $linkId) {\n      repoLink(repoId: $selectedRepoId) {\n        availableParentTopics(searchString: $searchString) {\n          synonyms {\n            value: id\n            label: displayName\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75eed5a5705cb2b184fc4b76241268c3";

export default node;
