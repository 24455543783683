/**
 * @generated SignedSource<<3111150d691cd02001270b28c2eff07a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateLinkParentTopicsInput = {
  clientMutationId?: string | null | undefined;
  linkId: string;
  parentTopicIds?: ReadonlyArray<string> | null | undefined;
  repoId: string;
};
export type updateLinkParentTopicsMutation$variables = {
  input: UpdateLinkParentTopicsInput;
};
export type updateLinkParentTopicsMutation$data = {
  readonly updateLinkParentTopics: {
    readonly link: {
      readonly repoLinks: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"EditRepoLink_repoLink">;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"Link_link">;
    };
  } | null | undefined;
};
export type updateLinkParentTopicsMutation = {
  response: updateLinkParentTopicsMutation$data;
  variables: updateLinkParentTopicsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateLinkParentTopicsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLinkParentTopicsPayload",
        "kind": "LinkedField",
        "name": "updateLinkParentTopics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Link",
            "kind": "LinkedField",
            "name": "link",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoLink",
                "kind": "LinkedField",
                "name": "repoLinks",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EditRepoLink_repoLink"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Link_link"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateLinkParentTopicsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateLinkParentTopicsPayload",
        "kind": "LinkedField",
        "name": "updateLinkParentTopics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Link",
            "kind": "LinkedField",
            "name": "link",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoLink",
                "kind": "LinkedField",
                "name": "repoLinks",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RepoLinkDetails",
                    "kind": "LinkedField",
                    "name": "details",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "selectedTopics",
                    "args": null,
                    "concreteType": "TopicConnection",
                    "kind": "LinkedField",
                    "name": "parentTopics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopicEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Topic",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "value",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "id",
                                "storageKey": null
                              },
                              {
                                "alias": "label",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inWikiRepo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayUrl",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newlyAdded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showRepoOwnership",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "TopicConnection",
                "kind": "LinkedField",
                "name": "displayParentTopics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "displayParentTopics(first:100)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9846ea3c5f5201c086e0b71d3d3829f",
    "id": null,
    "metadata": {},
    "name": "updateLinkParentTopicsMutation",
    "operationKind": "mutation",
    "text": "mutation updateLinkParentTopicsMutation(\n  $input: UpdateLinkParentTopicsInput!\n) {\n  updateLinkParentTopics(input: $input) {\n    link {\n      repoLinks {\n        ...EditRepoLink_repoLink\n      }\n      ...Link_link\n    }\n  }\n}\n\nfragment EditRepoLink_repoLink on RepoLink {\n  displayColor\n  linkId\n  details {\n    title\n    url\n  }\n  ...RepoLinkParentTopics_repoLink\n}\n\nfragment Link_link on Link {\n  displayTitle\n  displayUrl\n  id\n  loading\n  newlyAdded\n  viewerCanUpdate\n  showRepoOwnership\n  repoLinks {\n    inWikiRepo\n    displayColor\n  }\n  displayParentTopics(first: 100) {\n    edges {\n      node {\n        id\n        displayName\n      }\n    }\n  }\n}\n\nfragment RepoLinkParentTopics_repoLink on RepoLink {\n  linkId\n  selectedTopics: parentTopics {\n    edges {\n      node {\n        value: id\n        label: displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e4a67d514c47eff61e6d00a729b5a92";

export default node;
