/**
 * @generated SignedSource<<55a4306cb2cdc8f0c0b8973d913b4714>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectRepository_viewer$data = {
  readonly repos: {
    readonly edges: ReadonlyArray<{
      readonly isSelected: boolean;
      readonly node: {
        readonly fullName: string;
        readonly id: string | null | undefined;
      };
    } | null | undefined> | null | undefined;
  };
  readonly selectedRepo: {
    readonly id: string | null | undefined;
    readonly isPrivate: boolean;
  } | null | undefined;
  readonly " $fragmentType": "SelectRepository_viewer";
};
export type SelectRepository_viewer$key = {
  readonly " $data"?: SelectRepository_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectRepository_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectRepository_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Repository",
      "kind": "LinkedField",
      "name": "selectedRepo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPrivate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        }
      ],
      "concreteType": "RepositoryConnection",
      "kind": "LinkedField",
      "name": "repos",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RepositoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSelected",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Repository",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "repos(first:100)"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "d08bffdb91341c9892af76e7dc066965";

export default node;
