/**
 * @generated SignedSource<<9c48dfb7bcbe906bc6712352905f9742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicSearchPage_viewer$data = {
  readonly id: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Link_viewer" | "Topic_viewer">;
  readonly " $fragmentType": "TopicSearchPage_viewer";
};
export type TopicSearchPage_viewer$key = {
  readonly " $data"?: TopicSearchPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicSearchPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicSearchPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Topic_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Link_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "cac345452aa5b9fddc23abcf4cdd7cfb";

export default node;
