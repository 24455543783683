/**
 * @generated SignedSource<<9224080eeed11201ef9fbebef2c4d6d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchBox_view$data = {
  readonly queryInfo: {
    readonly phrases: ReadonlyArray<string>;
    readonly topics: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
    }>;
  };
  readonly " $fragmentType": "SearchBox_view";
};
export type SearchBox_view$key = {
  readonly " $data"?: SearchBox_view$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchBox_view">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBox_view",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QueryInfo",
      "kind": "LinkedField",
      "name": "queryInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phrases",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};

(node as any).hash = "07d9d5eb46df6cd50ef65eee503ce043";

export default node;
