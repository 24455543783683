/**
 * @generated SignedSource<<a16e88bf80c38a9a7c90ee0b76b93cb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddLink_parentTopic$data = {
  readonly id: string;
  readonly " $fragmentType": "AddLink_parentTopic";
};
export type AddLink_parentTopic$key = {
  readonly " $data"?: AddLink_parentTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddLink_parentTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddLink_parentTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "94e91b84d8f4274b222652e902d45618";

export default node;
