/**
 * @generated SignedSource<<71f7765f3406ee0ffb875d318eda6cda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type userSettingsQuery_query_Query$variables = {
  repoIds?: ReadonlyArray<string> | null | undefined;
  viewerId: string;
};
export type userSettingsQuery_query_Query$data = {
  readonly alerts: ReadonlyArray<{
    readonly id: string;
    readonly text: string;
    readonly type: AlertType;
  }>;
  readonly view: {
    readonly " $fragmentSpreads": FragmentRefs<"UserSettings_view">;
  };
};
export type userSettingsQuery_query_Query = {
  response: userSettingsQuery_query_Query$data;
  variables: userSettingsQuery_query_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repoIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "repoIds",
    "variableName": "repoIds"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userSettingsQuery_query_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSettings_view"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "userSettingsQuery_query_Query",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGuest",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb870079a6f00797c4a2b7b82d5e3274",
    "id": null,
    "metadata": {},
    "name": "userSettingsQuery_query_Query",
    "operationKind": "query",
    "text": "query userSettingsQuery_query_Query(\n  $viewerId: ID!\n  $repoIds: [ID!]\n) {\n  alerts {\n    id\n    text\n    type\n  }\n  view(repoIds: $repoIds, viewerId: $viewerId) {\n    ...UserSettings_view\n  }\n}\n\nfragment Account_view on View {\n  ...DeleteAccount_view\n}\n\nfragment DeleteAccount_view on View {\n  viewer {\n    id\n  }\n}\n\nfragment UserSettings_view on View {\n  viewer {\n    isGuest\n    id\n  }\n  ...Account_view\n}\n"
  }
};
})();

(node as any).hash = "d727c7923e4a115cf45130d2487acc58";

export default node;
