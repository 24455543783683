/**
 * @generated SignedSource<<0842559061686771039b3445336b58ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateTopic_updateTopic$data = {
  readonly displayName: string;
  readonly id: string;
  readonly " $fragmentType": "UpdateTopic_updateTopic";
};
export type UpdateTopic_updateTopic$key = {
  readonly " $data"?: UpdateTopic_updateTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateTopic_updateTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateTopic_updateTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "548cbdfc06909c808363d4b463a421d3";

export default node;
