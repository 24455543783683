/**
 * @generated SignedSource<<7cbe18706b08f6619584750d11245dd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddForm_parentTopic$data = {
  readonly id: string;
  readonly repoTopics: ReadonlyArray<{
    readonly repoId: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AddLink_parentTopic" | "AddTopic_parentTopic">;
  readonly " $fragmentType": "AddForm_parentTopic";
};
export type AddForm_parentTopic$key = {
  readonly " $data"?: AddForm_parentTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddForm_parentTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddForm_parentTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddLink_parentTopic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddTopic_parentTopic"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoTopic",
      "kind": "LinkedField",
      "name": "repoTopics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "repoId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "755665ef469c13ab65a20e2b5f33c34c";

export default node;
