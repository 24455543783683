/**
 * @generated SignedSource<<341be46643e6c5a7e08ce983e1648af2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTopic_topic$data = {
  readonly displayName: string;
  readonly repoTopics: ReadonlyArray<{
    readonly displayColor: any;
    readonly repo: {
      readonly id: string | null | undefined;
      readonly name: string;
    };
    readonly viewerCanUpdate: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EditRepoTopic_repoTopic" | "ViewRepoTopic_repoTopic">;
  }>;
  readonly " $fragmentType": "EditTopic_topic";
};
export type EditTopic_topic$key = {
  readonly " $data"?: EditTopic_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTopic_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTopic_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoTopic",
      "kind": "LinkedField",
      "name": "repoTopics",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Repository",
          "kind": "LinkedField",
          "name": "repo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerCanUpdate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayColor",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditRepoTopic_repoTopic"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ViewRepoTopic_repoTopic"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "a6e301c53f65c1ccaaf26a61bb45a622";

export default node;
