/**
 * @generated SignedSource<<0fa70fec4265be7a758215aec87ab4ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepoLinkParentTopics_repoLink$data = {
  readonly linkId: string;
  readonly selectedTopics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly label: string;
        readonly value: string;
      };
    } | null | undefined> | null | undefined;
  };
  readonly " $fragmentType": "RepoLinkParentTopics_repoLink";
};
export type RepoLinkParentTopics_repoLink$key = {
  readonly " $data"?: RepoLinkParentTopics_repoLink$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoLinkParentTopics_repoLink">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoLinkParentTopics_repoLink",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkId",
      "storageKey": null
    },
    {
      "alias": "selectedTopics",
      "args": null,
      "concreteType": "TopicConnection",
      "kind": "LinkedField",
      "name": "parentTopics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "value",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": "label",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepoLink",
  "abstractKey": null
};

(node as any).hash = "5f2fb132fae925c1d566d18f27cc1532";

export default node;
