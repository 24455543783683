/**
 * @generated SignedSource<<c4706c4562da6e66af468f616622c1f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRepoTopic_viewer$data = {
  readonly id: string | null | undefined;
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicSynonyms_viewer" | "RepoTopicTimerangeForm_viewer" | "RepoTopicTimerange_viewer">;
  readonly " $fragmentType": "EditRepoTopic_viewer";
};
export type EditRepoTopic_viewer$key = {
  readonly " $data"?: EditRepoTopic_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRepoTopic_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRepoTopic_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicTimerange_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicTimerangeForm_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicSynonyms_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6708f79a70409f2409849dcc737a0c74";

export default node;
