/**
 * @generated SignedSource<<0987c7b7f7d817d841179cac91083da9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Link_viewer$data = {
  readonly id: string | null | undefined;
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentType": "Link_viewer";
};
export type Link_viewer$key = {
  readonly " $data"?: Link_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Link_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Link_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0f352701e38d4eba3c463376eefdecbd";

export default node;
