/**
 * @generated SignedSource<<f77f77ff2196e618b087d05160b067e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepoTopicSynonyms_viewer$data = {
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentType": "RepoTopicSynonyms_viewer";
};
export type RepoTopicSynonyms_viewer$key = {
  readonly " $data"?: RepoTopicSynonyms_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicSynonyms_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoTopicSynonyms_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3b3bb9a8c9c12d1b124dacd2a3900f25";

export default node;
