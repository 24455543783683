/**
 * @generated SignedSource<<f7e073d7cb78c925ca0dbd7a8550c553>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicSearchPage_topic$data = {
  readonly children: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "Link";
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"Link_link">;
      } | {
        readonly __typename: "Topic";
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"Topic_topic">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    } | null | undefined> | null | undefined;
  };
  readonly displayName: string;
  readonly displayParentTopics: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly displayName: string;
        readonly id: string;
      };
    } | null | undefined> | null | undefined;
  };
  readonly id: string;
  readonly " $fragmentType": "TopicSearchPage_topic";
};
export type TopicSearchPage_topic$key = {
  readonly " $data"?: TopicSearchPage_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicSearchPage_topic">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchString"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicSearchPage_topic",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        (v2/*: any*/)
      ],
      "concreteType": "TopicConnection",
      "kind": "LinkedField",
      "name": "displayParentTopics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "displayParentTopics(first:100)"
    },
    {
      "alias": null,
      "args": [
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "searchString",
          "variableName": "searchString"
        }
      ],
      "concreteType": "SearchResultConnection",
      "kind": "LinkedField",
      "name": "children",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchMatchEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Topic_topic"
                    }
                  ],
                  "type": "Topic",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Link_link"
                    }
                  ],
                  "type": "Link",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "efb75f515a14e17009b06151cefc0d96";

export default node;
