/**
 * @generated SignedSource<<dd8f50f84110f26d88810d38dbe0b529>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Homepage_homepage_Query$variables = {
  repoIds?: ReadonlyArray<string> | null | undefined;
  viewerId: string;
};
export type Homepage_homepage_Query$data = {
  readonly view: {
    readonly activity: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly description: string;
          readonly " $fragmentSpreads": FragmentRefs<"LineItem_item">;
        };
      } | null | undefined> | null | undefined;
    };
    readonly stats: {
      readonly linkCount: number | null | undefined;
      readonly topicCount: number | null | undefined;
    };
  };
};
export type Homepage_homepage_Query = {
  response: Homepage_homepage_Query$data;
  variables: Homepage_homepage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "repoIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "viewerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "repoIds",
    "variableName": "repoIds"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewStats",
  "kind": "LinkedField",
  "name": "stats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Homepage_homepage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ActivityLineItemConnection",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityLineItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLineItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "LineItem_item"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "activity(first:3)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Homepage_homepage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ActivityLineItemConnection",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityLineItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLineItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "activity(first:3)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56ca4d3ce11e01451185bacc659df0cf",
    "id": null,
    "metadata": {},
    "name": "Homepage_homepage_Query",
    "operationKind": "query",
    "text": "query Homepage_homepage_Query(\n  $repoIds: [ID!]\n  $viewerId: ID!\n) {\n  view(repoIds: $repoIds, viewerId: $viewerId) {\n    stats {\n      linkCount\n      topicCount\n    }\n    activity(first: 3) {\n      edges {\n        node {\n          description\n          ...LineItem_item\n        }\n      }\n    }\n  }\n}\n\nfragment LineItem_item on ActivityLineItem {\n  description\n  createdAt\n}\n"
  }
};
})();

(node as any).hash = "459b4d9274a0a3a2b7595cc00dd54d41";

export default node;
