/**
 * @generated SignedSource<<4144b0780ba6f35065d559dc5264e050>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepoTopicTimerangeForm_viewer$data = {
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentType": "RepoTopicTimerangeForm_viewer";
};
export type RepoTopicTimerangeForm_viewer$key = {
  readonly " $data"?: RepoTopicTimerangeForm_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicTimerangeForm_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepoTopicTimerangeForm_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8ffe8886319bb358b6a5742ffb77eb21";

export default node;
