/**
 * @generated SignedSource<<9a20c6669a63f8abe0f067bbc385a492>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddTopic_parentTopic$data = {
  readonly id: string;
  readonly " $fragmentType": "AddTopic_parentTopic";
};
export type AddTopic_parentTopic$key = {
  readonly " $data"?: AddTopic_parentTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddTopic_parentTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddTopic_parentTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};

(node as any).hash = "f13b5aba8dd879b324cdb205bb13ed3e";

export default node;
