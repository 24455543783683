/**
 * @generated SignedSource<<a81ce51f2809f9df0ff42de4a539f5b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type UpdateTopicParentTopicsInput = {
  clientMutationId?: string | null | undefined;
  parentTopicIds?: ReadonlyArray<string> | null | undefined;
  repoId: string;
  topicId: string;
};
export type updateTopicParentTopicsMutation$variables = {
  input: UpdateTopicParentTopicsInput;
};
export type updateTopicParentTopicsMutation$data = {
  readonly updateTopicParentTopics: {
    readonly alerts: ReadonlyArray<{
      readonly id: string;
      readonly text: string;
      readonly type: AlertType;
    }>;
    readonly topic: {
      readonly " $fragmentSpreads": FragmentRefs<"Topic_topic">;
    };
  } | null | undefined;
};
export type updateTopicParentTopicsMutation = {
  response: updateTopicParentTopicsMutation$data;
  variables: updateTopicParentTopicsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTopicParentTopicsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTopicParentTopicsPayload",
        "kind": "LinkedField",
        "name": "updateTopicParentTopics",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "topic",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Topic_topic"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTopicParentTopicsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTopicParentTopicsPayload",
        "kind": "LinkedField",
        "name": "updateTopicParentTopics",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "topic",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loading",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "newlyAdded",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerCanUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showRepoOwnership",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RepoTopic",
                "kind": "LinkedField",
                "name": "repoTopics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inWikiRepo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayColor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "TopicConnection",
                "kind": "LinkedField",
                "name": "displayParentTopics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "displayParentTopics(first:100)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "942e7a20cbd423c910a51d4418b671c4",
    "id": null,
    "metadata": {},
    "name": "updateTopicParentTopicsMutation",
    "operationKind": "mutation",
    "text": "mutation updateTopicParentTopicsMutation(\n  $input: UpdateTopicParentTopicsInput!\n) {\n  updateTopicParentTopics(input: $input) {\n    alerts {\n      id\n      text\n      type\n    }\n    topic {\n      ...Topic_topic\n    }\n  }\n}\n\nfragment Topic_topic on Topic {\n  displayName\n  id\n  loading\n  newlyAdded\n  viewerCanUpdate\n  showRepoOwnership\n  repoTopics {\n    inWikiRepo\n    displayColor\n  }\n  displayParentTopics(first: 100) {\n    edges {\n      node {\n        id\n        displayName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25b88caff9431cd183b775a1e9f1984d";

export default node;
