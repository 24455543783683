/**
 * @generated SignedSource<<92433f1dee0cc2cba1c1f8072a480754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewRepoLink_repoLink$data = {
  readonly details: {
    readonly title: string;
  } | null | undefined;
  readonly displayColor: any;
  readonly " $fragmentType": "ViewRepoLink_repoLink";
};
export type ViewRepoLink_repoLink$key = {
  readonly " $data"?: ViewRepoLink_repoLink$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewRepoLink_repoLink">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewRepoLink_repoLink",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RepoLinkDetails",
      "kind": "LinkedField",
      "name": "details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RepoLink",
  "abstractKey": null
};

(node as any).hash = "3a009bfdf7190d78161dde1dde1a54b2";

export default node;
