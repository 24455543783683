/**
 * @generated SignedSource<<3c893f26931c21cdee459ec851bedc3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AlertType = "ERROR" | "SUCCESS" | "WARN" | "%future added value";
export type LayoutQuery$variables = {
  repoIds?: ReadonlyArray<string> | null | undefined;
  searchString?: string | null | undefined;
  viewerId: string;
};
export type LayoutQuery$data = {
  readonly alerts: ReadonlyArray<{
    readonly id: string;
    readonly text: string;
    readonly type: AlertType;
  }>;
  readonly view: {
    readonly viewer: {
      readonly " $fragmentSpreads": FragmentRefs<"DesktopNav_viewer" | "SelectedRepo_viewer">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"SearchBox_view">;
  };
};
export type LayoutQuery = {
  response: LayoutQuery$data;
  variables: LayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repoIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchString"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "repoIds",
    "variableName": "repoIds"
  },
  {
    "kind": "Variable",
    "name": "searchString",
    "variableName": "searchString"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DesktopNav_viewer"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectedRepo_viewer"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchBox_view"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "LayoutQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGuest",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatarUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Repository",
                "kind": "LinkedField",
                "name": "selectedRepo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPrivate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QueryInfo",
            "kind": "LinkedField",
            "name": "queryInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phrases",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Topic",
                "kind": "LinkedField",
                "name": "topics",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a17d155954f9c2598f003049f8ecb9e6",
    "id": null,
    "metadata": {},
    "name": "LayoutQuery",
    "operationKind": "query",
    "text": "query LayoutQuery(\n  $viewerId: ID!\n  $repoIds: [ID!]\n  $searchString: String\n) {\n  alerts {\n    id\n    text\n    type\n  }\n  view(repoIds: $repoIds, searchString: $searchString, viewerId: $viewerId) {\n    viewer {\n      ...DesktopNav_viewer\n      ...SelectedRepo_viewer\n      id\n    }\n    ...SearchBox_view\n  }\n}\n\nfragment DesktopNav_viewer on User {\n  isGuest\n  ...UserDropdown_viewer\n}\n\nfragment SearchBox_view on View {\n  queryInfo {\n    phrases\n    topics {\n      displayName\n      id\n    }\n  }\n}\n\nfragment SelectedRepo_viewer on User {\n  selectedRepo {\n    displayColor\n    fullName\n    isPrivate\n  }\n}\n\nfragment UserDropdown_viewer on User {\n  name\n  avatarUrl\n}\n"
  }
};
})();

(node as any).hash = "e54d5f140c929e516b16c6ad1deac546";

export default node;
