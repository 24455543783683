/**
 * @generated SignedSource<<29b12cb51318efb59b836beb53845992>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTopic_viewer$data = {
  readonly selectedRepoId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EditRepoTopic_viewer">;
  readonly " $fragmentType": "EditTopic_viewer";
};
export type EditTopic_viewer$key = {
  readonly " $data"?: EditTopic_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditTopic_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTopic_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedRepoId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditRepoTopic_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3fa8e022b1b31c3b1cef246b240d9e36";

export default node;
