/**
 * @generated SignedSource<<30d97b4b8149721517f7b3717ed210d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLinkInput = {
  clientMutationId?: string | null | undefined;
  linkId: string;
  repoId: string;
};
export type deleteLinkMutation$variables = {
  input: DeleteLinkInput;
};
export type deleteLinkMutation$data = {
  readonly deleteLink: {
    readonly clientMutationId: string | null | undefined;
    readonly deletedLinkId: string | null | undefined;
  } | null | undefined;
};
export type deleteLinkMutation = {
  response: deleteLinkMutation$data;
  variables: deleteLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLinkPayload",
    "kind": "LinkedField",
    "name": "deleteLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedLinkId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba4b3b38052e91c7fa74c43437816654",
    "id": null,
    "metadata": {},
    "name": "deleteLinkMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLinkMutation(\n  $input: DeleteLinkInput!\n) {\n  deleteLink(input: $input) {\n    clientMutationId\n    deletedLinkId\n  }\n}\n"
  }
};
})();

(node as any).hash = "d77768553a34d144152ebb11bd9a9e11";

export default node;
