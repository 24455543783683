/**
 * @generated SignedSource<<2dbe979562c3e6cd979d35866e487fc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentPage_recent_Query$variables = {
  repoIds?: ReadonlyArray<string> | null | undefined;
  topicId?: string | null | undefined;
  viewerId: string;
};
export type RecentPage_recent_Query$data = {
  readonly view: {
    readonly activity: {
      readonly " $fragmentSpreads": FragmentRefs<"LineItems_activity">;
    };
  };
};
export type RecentPage_recent_Query = {
  response: RecentPage_recent_Query$data;
  variables: RecentPage_recent_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "repoIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "repoIds",
    "variableName": "repoIds"
  },
  {
    "kind": "Variable",
    "name": "viewerId",
    "variableName": "viewerId"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "topicId",
    "variableName": "topicId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentPage_recent_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ActivityLineItemConnection",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LineItems_activity"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecentPage_recent_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "View",
        "kind": "LinkedField",
        "name": "view",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "ActivityLineItemConnection",
            "kind": "LinkedField",
            "name": "activity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityLineItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLineItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e50833f7679cbb6f464dc4f28e663ea9",
    "id": null,
    "metadata": {},
    "name": "RecentPage_recent_Query",
    "operationKind": "query",
    "text": "query RecentPage_recent_Query(\n  $viewerId: ID!\n  $repoIds: [ID!]\n  $topicId: String\n) {\n  view(repoIds: $repoIds, viewerId: $viewerId) {\n    activity(topicId: $topicId, first: 50) {\n      ...LineItems_activity\n    }\n  }\n}\n\nfragment LineItems_activity on ActivityLineItemConnection {\n  edges {\n    node {\n      createdAt\n      description\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "acb481c2b63f504bbb2cf53fef1831f6";

export default node;
