/**
 * @generated SignedSource<<998ede3ad874978bc08b493d36847533>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRepoTopic_repoTopic$data = {
  readonly displayColor: any;
  readonly topicId: string;
  readonly " $fragmentSpreads": FragmentRefs<"RepoTopicParentTopics_repoTopic" | "RepoTopicSynonyms_repoTopic" | "RepoTopicTimerange_repoTopic">;
  readonly " $fragmentType": "EditRepoTopic_repoTopic";
};
export type EditRepoTopic_repoTopic$key = {
  readonly " $data"?: EditRepoTopic_repoTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRepoTopic_repoTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRepoTopic_repoTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayColor",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicSynonyms_repoTopic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicTimerange_repoTopic"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepoTopicParentTopics_repoTopic"
    }
  ],
  "type": "RepoTopic",
  "abstractKey": null
};

(node as any).hash = "1d4bf654e1f548f9090e3497aa8acb18";

export default node;
