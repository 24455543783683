/**
 * @generated SignedSource<<220701b572057f779840ed257937c932>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewTopicPage_viewer$data = {
  readonly id: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"AddForm_viewer" | "Link_viewer" | "Topic_viewer">;
  readonly " $fragmentType": "ViewTopicPage_viewer";
};
export type ViewTopicPage_viewer$key = {
  readonly " $data"?: ViewTopicPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewTopicPage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewTopicPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddForm_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Link_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Topic_viewer"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ab0f8274c22ac872442838b431b5d0fa";

export default node;
