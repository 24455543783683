/**
 * @generated SignedSource<<04417fcaeb9081f8337a13f0da141a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LineItem_item$data = {
  readonly createdAt: any;
  readonly description: string;
  readonly " $fragmentType": "LineItem_item";
};
export type LineItem_item$key = {
  readonly " $data"?: LineItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"LineItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LineItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "ActivityLineItem",
  "abstractKey": null
};

(node as any).hash = "256d27ef87c21af30631f1133abfc3fd";

export default node;
